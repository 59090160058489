/* eslint-disable sort-keys */
export const industryChoices = {
	"adult-education": "College, University, and Adult Education",
	agriculture: "Agriculture, Forestry, Fishing and Hunting",
	"arts-entertainment": "Arts, Entertainment, and Recreation",
	broadcasting: "Broadcasting",
	construction: "Construction",
	electronics: "Computer and Electronics Manufacturing",
	finance: "Finance and Insurance",
	government: "Government and Public Administration",
	health: "Health Care and Social Assistance",
	hospitality: "Hotel and Food Services",
	"information-data": "Information Services and Data Processing",
	it: "IT",
	legal: "Legal Services",
	military: "Military",
	mining: "Mining",
	other: "Other Industry",
	"other-education": "Other Education Industry",
	"other-it": "Other Information Industry",
	"other-manufacturing": "Other Manufacturing",
	"primary-education": "Primary/Secondary (K-12) Education",
	publishing: "Publishing",
	"real-estate": "Real Estate, Rental and Leasing",
	retail: "Retail",
	"scientific-technical": "Scientific or Technical Services",
	telecommunications: "Telecommunications",
	transportation: "Transportation and Warehousing",
	utilities: "Utilities",
	wholesale: "Wholesale",
	"no-answer": "I prefer not to answer"
};

export const roleChoices = {
	admin: "Administrative Staff",
	ceo: "CEO",
	manager: "Manager",
	"other-c-level": "Other C-Level Executive",
	"other-professional": "Other Professional Non Technical",
	"other-technical": "Other Professional Technical",
	owner: "Self-employed/Partner/Owner",
	supervisor: "Supervisor/Lead",
	temp: "Temporary Employee",
	"no-answer": "I prefer not to answer"
};

export const raceChoices = {
	African: "African",
	"American Indian, Alaska Native": "American Indian, Alaska Native",
	"Black or African American": "Black or African American",
	"East Asian": "East Asian",
	"First Nations or Indigenous": "First Nations or Indigenous",
	"Hispanic or Latinx": "Hispanic or Latinx",
	"Middle Eastern (West Asian)": "Middle Eastern (West Asian)",
	"Native Hawaiian or other Pacific Islander": "Native Hawaiian or other Pacific Islander",
	"South Asian": "South Asian",
	"South East Asian": "South East Asian",
	"White (Caucasian)": "White (Caucasian)",
	"I prefer not to answer": "I prefer not to answer"
}

export const educationChoices = {
	college: "College Graduate (4 year)",
	doctoral: "Doctoral Degree (PhD)",
	"high-school": "High School or equivalent",
	masters: "Master's Degree (MS)",
	primary: "Primary/Grammar School",
	professional: "Professional Degree (MD, JD, etc.)",
	"some-college": "Some College",
	vocational: "Vocational/Technical School (2 year)",
	"no-answer": "I prefer not to answer"
}

export const genderChoices = {
	agender: "Agender",
	fluid: "Gender-fluid",
	man: "Man (any individual who identifies as male)",
	"non-binary": "Non-binary",
	other: "Other",
	woman: "Woman (any individual who identifies as female)",
	"no-answer": "I prefer not to answer"
}