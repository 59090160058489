/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

const EqqoVideo = ({
	className,
	config,
	onProgress,
	style,
	url
}) => (
	<div className={className} style={style}>
		<ReactPlayer
			config={config}
			controls
			height={'auto'}
			onProgress={onProgress}
			style={{
				maxWidth: "100%",
				minHeight: "100%",
				minWidth: "100%"
			}}
			url={url}
			width={'auto'}
		/>
	</div>
);

EqqoVideo.propTypes = {
	className: PropTypes.string,
	config: PropTypes.shape({}),
	onProgress: PropTypes.func,
	style: PropTypes.shape({}),
	url: PropTypes.string
};

EqqoVideo.defaultProps = {
	className: "",
	config: {},
	onProgress: null,
	style: {
		aspectRatio: "16 / 9"
	},
	url: ""
};

export default EqqoVideo;
