/* eslint-disable max-len, no-unused-vars */
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import Helmet from "react-helmet";
import PaymentForm from "./PaymentForm";
import SignUpStepIndicator from "./SignUpStepIndicator";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router";
import { useUser } from "../UserContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const options = {
	appearance: {
		theme: "stripe",
		variables: {
			colorPrimary: "#e1a605",
			colorText: "#3c2f2f"
		}
	}
};

const Payment = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	// This step of sign-up requires auth
	useEffect(() => {
		if (!user) {
			navigate("/sign-in");
		}
	}, []);

	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Account Payment"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<div className="d-flex flex-column align-items-center flex-md-row justify-content-md-between wrapper w-100">
					<img
						alt="LOGO" className="logo" height="152" src="/eqqo_logo_blue.png"
						width="472"
					/>
					<SignUpStepIndicator current={3} />
				</div>
				<Container className="bg-white wrapper">
					<h1>Account Payment</h1>
					<hr />
					<h2 className="mb-2">Subtotal</h2>
					<p className="mb-4 total-due">$195.00</p>
					<hr />
					<Elements options={options} stripe={stripePromise}>
						<PaymentForm />
					</Elements>
				</Container>
			</Col>
		</Row>
	);
};

export default Payment;
