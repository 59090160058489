/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

import { Col, Container, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import QuestionnaireForm from "./QuestionnaireForm";
import SignUpStepIndicator from "./SignUpStepIndicator";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useUser } from "../UserContext";

const Questionnaire = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	// This step of sign-up requires auth
	useEffect(() => {
		if (!user) {
			navigate("/sign-in");
		}
	}, []);
	return (
		<Row as="main" className="sign-up">
			<Helmet>
				<title>
					{"Create Your EQQO Account"}
				</title>
			</Helmet>

			<Col className="create-account flow" fluid="true">
				<div className="d-flex flex-column align-items-center flex-md-row justify-content-md-between wrapper w-100">
					<img
						alt="LOGO" className="logo" height="152" src="/eqqo_logo_blue.png"
						width="472"
					/>
					<SignUpStepIndicator current={2} />
				</div>
				<Container className="bg-white flow wrapper">
					<h1>Respondent Questionnaire</h1>
					<QuestionnaireForm />
				</Container>
			</Col>
		</Row>
	);
};
export default Questionnaire;
