/* eslint-disable no-warning-comments, no-console */
import { Button, Container } from "react-bootstrap";
import { NavLink, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";

const ProfilePageHeader = ({ showMenu }) => {
	const { id } = useParams();
	const location = useLocation();
	// TODO: do this way better somehow
	const headerText = useMemo(() => {
		if (id) {
			return `Assessment – Scenario ${id} of 8`;
		}
		const page = location.pathname.replace('/profile/', '');
		switch(page) {
		case 'help':
			return `Help`;
		case 'account':
			return `My Account`;
		default:
			return "Assessment";
		}
	}, [location]);
	return (
		<Container className="profile-page-top-header align-items-center d-flex flex-wrap justify-content-between px-0 py-4">
			<Button className="nav-menu-button" onClick={showMenu}>Menu</Button>
			<h1 className="fs-32 mb-0">{headerText}</h1>
			<NavLink className="d-flex align-items-center" to="/profile/account">
				<UserCircleIcon className="me-1" height={20} width={20} /> My Account
			</NavLink>
		</Container>
	);
};


ProfilePageHeader.propTypes = {
	showMenu: PropTypes.func,
};

ProfilePageHeader.defaultProps = {
	showMenu: null
}

export default ProfilePageHeader;
