/* eslint-disable max-len */

import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import ImagePlaceholder from "../../icons/ImagePlaceholder";
import { useAuth } from "../services/eqqo.auth.service";
import { useEffect } from "react";

const Landing = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	
	// User should become defined after registration.
	useEffect(() => {
		if (user) {
			navigate("/sign-in/confirm");
		}
	}, [user]);

	return (
		<Row as="main" className="sign-up cover">
			<Helmet>
				<title>
					{"Welcome to EQQO"}
				</title>
			</Helmet>

			<Col className="sign-up-content flow" md={7} xs={12}>
				<img
					alt="LOGO" className="logo" height="152" src="/eqqo_logo_blue.png"
					width="472"
				/>
				<h1>Welcome to EQQO</h1>
				<NavLink className="btn btn-gradient text-center" to="/sign-up">Get Started</NavLink>
				<NavLink className="btn btn-eqqo-purple text-center" to="/sign-in">Sign In</NavLink>
			</Col>
			<Col className="sign-up-decoration" md={5} xs={12}>
				<ImagePlaceholder />
			</Col>
		</Row>
	);
}

export default Landing;
