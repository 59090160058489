/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import { Button, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import ProfileNav from "./ProfileNav";
import ProfilePageHeader from "./ProfilePageHeader";
import { useUser } from "../UserContext";

const Profile = () => {
	const { user } = useUser();
	const navigate = useNavigate();
	const location = useLocation();

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	// Profile routes require auth
	useEffect(() => {
		if (!user) {
			navigate("/sign-in");
		}
	}, []);
	// Close nav on location change
	useEffect(() => {
		handleClose();
	}, [location]);
	return (
		<Row as="main" className="profile | bg-eqqo-blue-white">
			<Helmet>
				<title>{"EQQO Profile"}</title>
			</Helmet>

			<Col className="profile__sidebar bg-primary" lg={3}>
				<Offcanvas className="h-100" onHide={handleClose} responsive={'lg'} show={show}>
					<ProfileNav handleClose={handleClose} />
				</Offcanvas>
			</Col>
			<Col className="profile__content" lg={9}>
				<ProfilePageHeader showMenu={handleShow} />
				<Container className="bg-white rounded-2">
					<Outlet />
				</Container>
			</Col>
		</Row>
	);
};

export default Profile;
