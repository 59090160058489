/* eslint-disable camelcase, lines-around-comment, no-console, newline-per-chained-call */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars, id-length */
/* eslint-disable */
import * as yup from "yup";
import { Accordion, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Formik } from "formik";
import { educationChoices, genderChoices, industryChoices, raceChoices, roleChoices } from "./questionnaireChoices";
import saveUserProfile from "./saveProfileMutation";
import { useAuth } from "../services/eqqo.auth.service";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

const QuestionnaireForm = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		birthYear: yup.string().matches(/^[0-9]{4}$/u, 'Must be exactly 4 digits'),
		firstName: yup.string().required('First name is required.'),
		lastName: yup.string().required('Last name is required.'),
		zip: yup.string().matches(/^[0-9]{5}$/u, 'Must be exactly 5 digits').required('Zip code is required.')
	});

	const setInitialValues = useMemo(() => {
		const values = {
			birthYear: "",
			education: "",
			firstName: "",
			gender: "",
			industry: "",
			lastName: "",
			position: "",
			race: "",
			zip: ""
		};
		return values;
	}, []);

	const queryClient = useQueryClient();
	const {
		isLoading,
		mutateAsync
	} = saveUserProfile(
		useMutation,
		user.databaseId,
		{
			onSuccess: async () => {
				await queryClient.refetchQueries("userProfile");
				await queryClient.refetchQueries(["auth-user"]);

				navigate("/sign-up/pay");
			}
		}
	);

	const handleFormSubmit = useCallback(async (values) => {
		// birthYear needs to default as empty string, otherwise validation breaks
		// We must convert it to a number if the user didn't enter one to satisfy the database
		if (values.birthYear === "") {
			values.birthYear = 0; // ensure this is not an empty string
		}
		try {
			await mutateAsync({ userProfile: values });
		} catch (error) {
			console.log(error);
		}
	});
	return (
		<Formik
			initialValues={setInitialValues}
			onSubmit={handleFormSubmit}
			validationSchema={validationSchema}
		>
			{(formik) => (
				<Form noValidate onSubmit={formik.handleSubmit}>
					<h2 className="fs-6 mb-2">Required</h2>
					<Row>
						<Col sm={6}>
							<Form.Label>First Name</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.firstName)}
								name="firstName"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name="firstName" type="invalid">
								{formik.errors.firstName ? <p className="mb-0">{formik.errors.firstName}</p> : null}
							</Form.Control.Feedback>
						</Col>
						<Col className="mt-4 mt-sm-0" sm={6}>
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.lastName)}
								name="lastName"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name="lastName" type="invalid">
								{formik.errors.lastName ? <p className="mb-0">{formik.errors.lastName}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<Form.Label>Zip Code</Form.Label>
							<Form.Control
								isInvalid={Boolean(formik.errors.zip)}
								name="zip"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								type="text"
							/>
							<Form.Control.Feedback name="zip" type="invalid">
								{formik.errors.zip ? <p className="mb-0">{formik.errors.zip}</p> : null}
							</Form.Control.Feedback>
						</Col>
					</Row>
					
					<hr className="my-4" />
					
					<h2 className="fs-6 mb-2">Optional</h2>
					<Row>
						<Col md={6}>
							<Form.Label>Which of the following best describe the industry you work in?</Form.Label>
							<Form.Select
								name="industry"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							>
								<option>Select Industry</option>
								{Object.entries(industryChoices).map(([field, value]) => (
									<option key={field} value={field}>{value}</option>
								))}
							</Form.Select>
						</Col>
						<Col className="mt-4 mt-md-0" md={6}>
							<Form.Label>Which of the following best describes your role?</Form.Label>
							<Form.Select
								name="position"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							>
								<option>Select Role</option>
								{Object.entries(roleChoices).map(([field, value]) => (
									<option key={field} value={field}>{value}</option>
								))}
							</Form.Select>
						</Col>
					</Row>
					<Form.Label>Which of the following best describes your race or ethnicity?</Form.Label>
					{Object.entries(raceChoices).map(([field, value], idx) => (
						<Form.Check
							id={`race-${idx}`}
							key={field}
							label={value}
							name="race"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							value={value}
						/>
					))}
					<hr />
					<p>Want to help our data nerds and earn some extra credit by sharing extra info?</p>
					<Accordion className="mt-2" defaultActiveKey="0">
						<Accordion.Item eventKey="1">
							<Accordion.Header>Answer a Few More Questions</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col md={6}>
										<Form.Label>What year were you born?</Form.Label>
										<Form.Control
											isInvalid={Boolean(formik.errors.birthYear)}
											name="birthYear"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											type="number"
										/>
										<Form.Control.Feedback name="birthYear" type="invalid">
											{formik.errors.birthYear ? <p className="mb-0">{formik.errors.birthYear}</p> : null}
										</Form.Control.Feedback>
									</Col>
									<Col className="mt-4 mt-md-0" md={6}>
										<Form.Label>What best describes the highest level of education you've completed?</Form.Label>
										<Form.Select
											name="education"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
										>
											<option>Select Education Level</option>
											{Object.entries(educationChoices).map(([field, value]) => (
												<option key={field} value={field}>{value}</option>
											))}
										</Form.Select>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Form.Label>Which of the following best describes your gender identity?</Form.Label>
										<Form.Select
											name="gender"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
										>
											<option>Select Gender</option>
											{Object.entries(genderChoices).map(([field, value]) => (
												<option key={field} value={field}>{value}</option>
											))}
										</Form.Select>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<Button
						className="btn-gradient mt-4 w-100"
						disabled={isLoading}
						onClick={formik.handleSubmit}
						type="submit"
						variant="primary"
					>
						{
							isLoading
								? <Spinner animation="border" size="sm" />
								: <>Continue <ArrowRightIcon height={20} width={20} /></>
						}
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default QuestionnaireForm;
